import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CsvDownloaderService {

  public download(data: any[], fileName: string = "report.csv") {
    let csvData = "";
    if (data.length > 0) {
      const keys = Object.keys(data[0]);
      csvData += keys.join(";") + "\n";
      data.forEach((row) => {
        const values = keys.map(key => row[key]);
        csvData += values.join(";") + "\n";
      });
    }

    this.downloadFile(csvData, fileName);
  }
  
  private downloadFile(csvLikeData: string, fileName: string = "report.csv") {
    const convertedBase64 = window.btoa(csvLikeData);
    const blob = this.b64toBlob(convertedBase64, 'text/csv');
    this.base64Downloader(blob, fileName);
  }

  private base64Downloader(blobData: any, fileName: string, contentType: string = 'application/octet-stream') {
    const blob = new Blob([blobData], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
  }

  public b64toBlob(b64Data: string, contentType: string = 'application/octet-stream') {
    contentType = contentType || '';
    const sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

}
